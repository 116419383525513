* {
  font-family: 'Open Sans', Helvetica, sans-serif;
}

body {
  background: #f5f8fa !important;
}

// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~react-image-gallery/styles/css/image-gallery.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout Skins
@import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
@import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
@import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
@import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';

// for images gallery (in product view page)

.image-gallery-thumbnails {
  padding: 0;
  margin-top: 15px;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
}

.image-gallery-thumbnail {
  border: none;
  width: 155px;
  height: 100px;
  margin-right: 15px;
}

.image-gallery-thumbnail:last-child {
  margin-right: 0;
}

.image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail-inner img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: none;
}

// fix material ui icons in safari
.MuiSvgIcon-root {
  transform: scale(1);
}

// Header Skins
.kt-header-base-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/dark.scss';
}

// Header Menu Skins
.kt-header-menu-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
}
.kt-header-menu-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss';
}

// Brand Skins
.kt-brand-dark {
  @import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
  @import './_metronic/_assets/sass/global/layout/brand/skins/light.scss';
}

// Aside skins
.kt-aside-dark {
  @import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
  @import './_metronic/_assets/sass/global/layout/aside/skins/light.scss';
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

@media screen and (max-width: 500px) {
  .MuiTablePagination-root {
    width: 100%;
  }
  .MuiToolbar-root {
    justify-content: space-between;
  }
  .MuiTablePagination-spacer,
  .MuiTablePagination-spacer + .MuiTablePagination-caption {
    display: none;
  }
  .MuiTablePagination-select {
    padding-left: 0 !important;
  }
  .MuiTablePagination-toolbar,
  .MuiToolbar-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .MuiTablePagination-selectRoot {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
}
